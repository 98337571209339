import React, { useReducer, createContext } from 'react';

export const DISMISS_POPUP = 'DISMISS_POPUP';
export const ACTIVATE_POPUP = 'ACTIVATE_POPUP';
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
export const COMPLETE_WEBINAR_SIGNUP = 'COMPLETE_WEBINAR_SIGNUP';

const initialAppState = {
  isEligibleForPopup: true,
  isShowingPopup: false,
  userRegisteredForWebinar: false,
};

export const AppStateContext = createContext(initialAppState);

function appStateReducer(state, action) {
  if (action.type === DISMISS_POPUP) {
    return { ...state, isEligibleForPopup: false, isShowingPopup: false };
  }

  if (action.type === ACTIVATE_POPUP) {
    return { ...state, isEligibleForPopup: true };
  }

  if (action.type === SHOW_POPUP) {
    return { ...state, isShowingPopup: true };
  }

  if (action.type === HIDE_POPUP) {
    return { ...state, isShowingPopup: false };
  }

  if (action.type === COMPLETE_WEBINAR_SIGNUP) {
    return {
      ...state,
      userRegisteredForWebinar: true,
    };
  }

  return state;
}

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appStateReducer, initialAppState);

  return (
    <AppStateContext.Provider value={[state, dispatch]}>
      {children}
    </AppStateContext.Provider>
  );
};
