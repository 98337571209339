// Inject global styles
import "gatsby-theme-kalanso-ui/src/styles/global.scss"
import "gatsby-theme-kalanso-ui/src/styles/font-family.scss"

import React from "react"

import { AppStateProvider } from './src/context/AppStateProvider';

export const wrapRootElement = ({ element }) => {
  return <AppStateProvider children={element} />;
};

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}