import React, { createContext, useState, useEffect } from 'react';

export const SplitTestContext = createContext('');

export const SplitTestProvider = ({ children }) => {
  const [branch, setBranch] = useState();

  useEffect(() => {
    setBranch(window.NF_AB);
  }, []);

  return (
    <SplitTestContext.Provider value={branch}>
      {children}
    </SplitTestContext.Provider>
  );
};
