/**
 * See: https://www.netlify.com/blog/2019/09/11/netlify-pro-tip-using-split-testing-to-power-private-beta-releases/
 *
 * Whenever we run split tests we'll need to set up proper
 * redirect rules in our site's `netlify.toml` file in order
 * to make the urls we send people to pretty.
 *
 * For example:
 *
 *     [[redirects]]
 *       from = "https://a-branch-deploy.biocharwebinar.com"
 *       to = "https://biocharwebinar.com:"
 *       status = 301
 *       force = true
 *
 *     [[redirects]]
 *       from = "https://a-branch-deploy.biocharwebinar.com/*"
 *       to = "https://biocharwebinar.com/:splat"
 *       status = 301
 *       force = true
 *
 */

import { canUseDOM } from 'kalanso-plugin-utils';

const COOKIE_NAME = 'nf_ab';

export const leaveSplitTest = ({ reload = true }) => {
  if (canUseDOM()) {
    // clear and expire the cookie.
    document.cookie = `${COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    if (reload) {
      // reload the page to pick up the new option
      window.location.reload();
    }
  }
};

export const enterSplitTest = ({ branch, reload = true }) => {
  if (!branch) {
    throw new Error(`Called enterSplitTest without branch name.`)
  }

  if (canUseDOM()) {
    // Set the cookie with the name of the branch for 1 year
    var now = new Date();
    var expires = now.getTime() + 1000 * 3600 * 24 * 365;
    now.setTime(expires);

    document.cookie = `${COOKIE_NAME}=${branch};expires=${now.toUTCString()};`;

    if (reload) {
      // reload the page to pick up the new split test cookie value
      // (forcing the browser to re-request it, rather than serving from browser cache)
      window.location.reload();
    }
  }
};
