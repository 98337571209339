import React from 'react'

import queryString from 'query-string';
import { updateLocation } from 'serialize-query-params';

import { SplitTestProvider, enterSplitTest, leaveSplitTest } from './index';

export const wrapRootElement = ({ element }) => {
  return <SplitTestProvider children={element} />;
};

export const onClientEntry = () => {
  const qs = queryString.parse(window.location.search);

  const enterBranch = qs.nf_ab;
  const leaveBranch = qs.nf_abx;

  // Remove `nf_ab` and `nf_abx` from URL and keep everything else
  delete qs.nf_ab;
  delete qs.nf_abx;

  const locationMinusSplitTestParams = updateLocation(qs, window.location);

  const branch = window.NF_AB;
  const isBranchDefined = Boolean(branch);
  const isEnterBranchDefined = Boolean(enterBranch);

  if (isEnterBranchDefined) {
    if (isBranchDefined && branch !== enterBranch) {
      enterSplitTest({ branch: enterBranch, reload: false });
      window.location.assign(locationMinusSplitTestParams.href);
    }
  } else if (leaveBranch === 't') {
    leaveSplitTest({ reload: false });
    window.location.assign(locationMinusSplitTestParams.href);
  }
};
